import React from "react";

const Story = () => (
  <div className="relative py-12 bg-gray-100 overflow-hidden">
    <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg
          className="absolute top-12 left-full transform translate-x-32"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg
          className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="f210dbf6-a58d-4871-961e-36d5016a0f49"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg
          className="absolute bottom-12 left-full transform translate-x-32"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="d3eb07ae-5182-43e6-857d-35c643af9034"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div className="relative max-w-7xl lg:max-w-4xl px-4 sm:px-6 md:px-16 lg:px-0 text-lg prose mx-auto">
      <h1>
        <span className="block text-base text-left lg:text-center text-highlight font-semibold tracking-wide uppercase">
          A Tale of Two Pandemics
        </span>
        <span className="mt-2 block text-3xl text-left lg:text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span>
            The year 2020 was the best of times&#8230;
          </span>
          <span className="mt-3 md:mt-0 block">
            if you&rsquo;re a fraudster
          </span>
        </span>
      </h1>
      <p className="mt-6 text-left lg:text-center text-xl text-gray-500 leading-8">
        For the rest of us, it was the worst of times. The tale of two pandemics is one of fraudsters rejoicing at the rapid shift to digital banking and commerce, and of consumers getting swindled by purchase, implementation, schemes and account takeovers.
        With opportunity comes risk, however WAIVPAY have the experience and solutions to help you on the journey.
      </p>
    </div>
  </div>
);

export default Story;
